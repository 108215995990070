import React, { useContext, useRef, useState, useEffect, createRef } from "react"
import useTranslations from "./../utils/useTranslations"
import Button from "../components/button"
import { CompatibilityContext } from "./compatibilityContext";
import { LocaleContext } from "./layout"
import { PromoPopupHandler, promoPrices, getPromoLinks } from '../components/promo'
import {WebAf, WebPro, WebProVpn} from "./cartLinks"
import { ANDROID_LINK_FREE, ANDROID_LINK_PRO, getAndroidLink } from "../utils/getAndroidLink";
import { getCountry } from "../utils/country";
import BenchClient from "../utils/benchClient"

const ComparisonPanels = ({pageContext: { text, pageName, isMac, isKasperskyKO, isKasperskyTR }, noShadow, children}) => {
  const t = useTranslations(text)
  const page = pageName
  const compatibilityHandler = useContext(CompatibilityContext)
  const { locale } = React.useContext(LocaleContext)
  const googlePlayButton = `/images/google-play-badges/google-play-badge-${locale}.png`

  const panel = {}
  panel.basic = useRef(null);
  panel.adFree = useRef(null);
  panel.pro = useRef(null);
  panel.proVPN = useRef(null);

  const freePriceInnerRef = useRef(null);
  const [freePriceStyle, setFreePriceStyle] = useState({});

  

  const [isProVPNSupported, setIsProVPNSupported] = useState(true);

  const [androidLinkFree, setAndroidLinkFree] = useState(ANDROID_LINK_FREE)
  const [androidLinkPro, setAndroidLinkPro] = useState(ANDROID_LINK_PRO)

  let androidfree, classiccompare, homepage, btwebfree, panelGutter, productOs, pageType, titleProduct, product, webPremium, webcompare, webProOffer, classicfree, classicPremium;
  if (page === 'index') { homepage = true; panelGutter = true; productOs = ["Win"]; pageType = "home"}
  if (page === 'web') { btwebfree = true; panelGutter = false; product = "Web"; productOs = ["Win"]; titleProduct=true; }
  if (page === 'web_mac') { btwebfree = true; panelGutter = false; product = "Web"; productOs = ["Osx"]; titleProduct=true;}
  if (page === 'web_pro') { webPremium = true; panelGutter = false; product = "Web"; productOs = ["Win", "Osx"]; titleProduct=true;}
  if (page === 'web_compare') { webcompare = true; panelGutter = false; product = "Web"; productOs = ["Win", "Osx"]; titleProduct=true; var showEULA=isMac}
  if (page === 'webpro-offer') { webProOffer = true; panelGutter = true; product = "Web"; productOs = ["Win", "Osx"]; titleProduct=true;}
  if (page === 'desktop') { classicfree = true; product = "Classic"; productOs = ["Win"]; pageType = "panels"; titleProduct=true}
  if (page === 'pro') { classicPremium = true; product = "Classic"; productOs = ["Win"]; pageType = "premium"; titleProduct=true}
  if (page === 'desktop_compare') { classiccompare = true; product = "Classic"; productOs = ["Win"]; pageType = "dedicatedpanels"; titleProduct=true}
  if (page === 'mobile') { androidfree = true; product = "Android"; productOs = ["Android"]}
  const isPromoCalloutPage = homepage || btwebfree || webPremium || webcompare || classicfree || classicPremium || classiccompare
  const {showPromoDiscount} = PromoPopupHandler()
  const showAnyPromo = typeof showPromoDiscount === 'boolean' ? showPromoDiscount: Object.values(showPromoDiscount).some(promo => promo === true);
  const showProVpnPromo = typeof showPromoDiscount === 'boolean' ? showPromoDiscount : showPromoDiscount.proVpn;
  const showProPromo = typeof showPromoDiscount === 'boolean' ? showPromoDiscount : showPromoDiscount.pro;
  const showAdFreePromo = typeof showPromoDiscount === 'boolean' ? showPromoDiscount : showPromoDiscount.adFree;   
  const promoPercent = promoPrices.percent;
  let panelShadowNone = (noShadow) ? "panelShadowNone" : "" 
  let panelTriplet = (panelGutter) ? "sexy-panel" : ""
  let panelNoBorder = (panelGutter) ? "sexy-panel-too" : ""
  let panelPromo = (showAnyPromo) ? "panel-promo" : ""


  const handleClick = (e, link, type, homepageProduct)=>{
    const action = type === "basic" ? "download" : "buy";
    const productName = product ? product : (homepageProduct ? homepageProduct : 'Unknown');

    compatibilityHandler(e, `${action}ing`, productName, productOs, link)
    BenchClient.ping(`click.${productName.toLowerCase()}_${action}.${type}`);
  }
  let [linkBasic, setLinkBasic] = useState((classiccompare || classicPremium || classicfree) ? "/downloads/complete/track/stable/os/win/" : (isMac ? '/web/downloads/complete/track/stable/os/mac/' : "/web/downloads/complete/track/stable/os/win/"));
  let [webProVPNLinkFinal, setWebProVPNLinkFinal] = useState((isMac) ? WebProVpn.webMac : WebProVpn.webWin);
  let [webProLinkFinal, setWebProLinkFinal] = useState((isMac) ? WebPro.webMac    : WebPro.webWin);
  let [webAdFreeLinkFinal, setWebAdFreeLinkFinal] = useState((isMac) ? WebAf.webMac     : WebAf.webWin);
  
  let [proVPNLinkFinal, setProVPNLinkFinal] = useState((pageType) ? `https://store.utorrent.com/849/purl-utwebprovpn6995?x-source=utclassicprovpn-${pageType}` : "https://store.utorrent.com/849/purl-utwebprovpn6995");
  let [proLinkFinal, setProLinkFinal] = useState((pageType) ? `https://store.utorrent.com/849/purl-utproweb?x-source=utclassicpro-${pageType}` : "https://store.utorrent.com/849/purl-utproweb");
  let [adFreeLinkFinal, setAdFreeLinkFinal] = useState((pageType) ? `https://store.utorrent.com/849/purl-utafwebw?x-source=utclassicadfree-${pageType}` : "https://store.utorrent.com/849/purl-utafwebw");
  useEffect(()=> {
    setLinkBasic(isMac ? '/web/downloads/complete/track/stable/os/mac/' : "/web/downloads/complete/track/stable/os/win/");
    if(classiccompare || classicPremium || classicfree){
      setLinkBasic("/downloads/complete/track/stable/os/win/")
    }
    // web
    let newWebProVPNLink = (isMac) ? WebProVpn.webMac : WebProVpn.webWin;
    let newWebProLink = (isMac) ? WebPro.webMac : WebPro.webWin;
    let newWebAdFreeLink = (isMac) ? WebAf.webMac : WebAf.webWin;
    
    //classic
    let newProVPNLink = (pageType) ? `https://store.utorrent.com/849/purl-utwebprovpn6995?x-source=utclassicprovpn-${pageType}` : "https://store.utorrent.com/849/purl-utwebprovpn6995";
    let newProLink = (pageType) ? `https://store.utorrent.com/849/purl-utproweb?x-source=utclassicpro-${pageType}` : "https://store.utorrent.com/849/purl-utproweb";
    let newAdFreeLink = (pageType) ? `https://store.utorrent.com/849/purl-utafwebw?x-source=utclassicadfree-${pageType}` : "https://store.utorrent.com/849/purl-utafwebw";

    if(product === "Web"){
      newProVPNLink = newWebProVPNLink;
      newProLink = newWebProLink;
      newAdFreeLink = newWebAdFreeLink;
    }
    if (showProVpnPromo) {
      const newLinks = getPromoLinks({newWebProVPNLink, newProVPNLink});
      newWebProVPNLink = newLinks.newWebProVPNLink;
      newProVPNLink = newLinks.newProVPNLink;
    }

    if (showProPromo) {
      const newLinks = getPromoLinks({newWebProLink, newProLink});
      newWebProLink = newLinks.newWebProLink;
      newProLink = newLinks.newProLink;
    }
    
    if (showAdFreePromo) {
      const newLinks = getPromoLinks({newWebAdFreeLink, newAdFreeLink});
      newWebAdFreeLink = newLinks.newWebAdFreeLink;
      newAdFreeLink = newLinks.newAdFreeLink
    }

    // web
    setWebProVPNLinkFinal(window.appendPartitionURLParameter(newWebProVPNLink));
    setWebProLinkFinal(window.appendPartitionURLParameter(newWebProLink));
    setWebAdFreeLinkFinal(window.appendPartitionURLParameter(newWebAdFreeLink));
    // classic
    setProVPNLinkFinal(window.appendPartitionURLParameter(newProVPNLink));    
    setProLinkFinal(window.appendPartitionURLParameter(newProLink));
    setAdFreeLinkFinal(window.appendPartitionURLParameter(newAdFreeLink));
  }, [isMac]);

  ///////

  useEffect(() => {
    const adjustFreePriceFontSize = () => {
      let parentWidth = panel.basic.current.clientWidth
      if(parentWidth !== 0){
        let width = freePriceInnerRef.current.clientWidth
        if(width > parentWidth-30){
          let fs = window.getComputedStyle(freePriceInnerRef.current, null).getPropertyValue('font-size');
          fs = parseFloat(fs)
          let newFs = fs-5 + "px"
          setFreePriceStyle({
            fontSize:newFs,
            lineHeight:'normal'
          })
        }
      }
    };
    adjustFreePriceFontSize();
    setAndroidLinkFree(getAndroidLink('free'))
    setAndroidLinkPro(getAndroidLink('pro'))
  }, [panel.basic]);

  useEffect(() => {
    const set = async () => {
      const proVpnNotSupportedGeos = ['IN'];
      let countryCode = await getCountry(); 
    
      if(proVpnNotSupportedGeos.includes(countryCode) && (homepage === true || product === "Web" || product === "Classic")){
        setIsProVPNSupported(false);
      }
    }
    set();
  }, [homepage, product])
  
  const [isAPartition, setIsAPartition] = useState(false);
  useEffect(() => {
    setIsAPartition(window.utIsAPartition ? true : false);
  }, []);

  return (    
     <>
        <div id="comparison-panels" className={`container mt-5 mb-sm-5 px-0 ${classicPremium || classicfree || homepage || btwebfree  || webPremium  ? 'staggered-design' : ''}`}>
          <div className={homepage || androidfree || webProOffer ? 'row d-flex align-items-start justify-content-center mx-0' : 'row d-flex align-items-start justify-content-center column-gaps mx-0 position-relative z-1'}>
            <div ref={panel.proVPN} className={androidfree === true || !isProVPNSupported ? 'd-none' : `col-12 col-sm-10 col-md-8 col-lg-3 panels-col-lg-3 order-3 order-lg-1 d-flex flex-column align-items-center justify-content-start panelShadow panelWidth panelOther ${panelShadowNone} ${panelNoBorder} ${panelPromo}`}>
              <div className="panelTitle mt-4">PRO+VPN</div>
              { titleProduct &&
                <p className="panelTitleProduct"> {t(`for ${product}`)}</p>
              }
              <div className="panelSubtitle mt-3 mb-4"><div>{t("provpnPanelTitle")}</div></div>
              <div className="panelSubline mb-4">&nbsp;</div>
              <div>
                <div className="panelsPrice">
                  {/* {((!showPromoDiscount) || !isPromoCalloutPage || isKasperskyTR || isKasperskyKO) */}
                  {(!showProVpnPromo || !isPromoCalloutPage || isKasperskyTR || isKasperskyKO)
                    ? "$69.95"
                    : (
                      <>
                        <div className="promo-limited-time">{t("Limited Time Only")}</div>
                        <div className="promo-percent-off">{promoPercent} {t("Off")}</div>
                        <div className="promo-list-price">
                          {t("List Price")}:
                          <span className="promo-original-price">$69.95</span>
                        </div>
                      </>
                    )

                  }
                </div>
                <div className="panelsPeriod mb-3">{t("per year")}</div>
              </div>
              <Button panel={panel.proVPN} onClick={(e) => handleClick(e, proVPNLinkFinal, "provpn")} id="panels-provpn-buynow" href={proVPNLinkFinal} type="button" className={homepage === true ? 'd-none' : 'btn btn-outline-primary button-outlined text-size-16'}>{t("BUY NOW")}</Button>
              <div className={homepage === true ? 'd-flex align-items-center justify-content-start' : 'd-none'}>
                <Button id="panels-hp-web-provpn-link" onClick={(e) => handleClick(e, webProVPNLinkFinal, "provpn", "Web")} href={webProVPNLinkFinal} type="button" className="btn btn-primary-panels button-filled button-panels">WEB</Button>
                <Button id="panels-hp-classic-provpn-link" onClick={(e) => handleClick(e, proVPNLinkFinal, "provpn", "Classic")} href={proVPNLinkFinal} type="button" className="btn btn-outline-primary button-outlined ml-2 button-panels">CLASSIC</Button>
              </div>
              <div className="mt-4 mw-100">
                <div className={classicPremium === true || classicfree === true || homepage === true || btwebfree === true || webPremium === true ? 'panelBullets w-100 uniform' : 'panelBullets w-100 uniform-short'}>
                  <li>{t("provpnBullet1")}</li>
                  <li>{t("provpnBullet2")}</li>
                  <li>{t("provpnBullet3")}</li>
                  <li className={classiccompare === true || btwebfree === true || webProOffer ? 'd-none' : ''}>{t("provpnBullet4")}</li>
                  <li className={classiccompare === true || webcompare === true || classicPremium === true || webPremium === true || btwebfree === true || homepage === true || classicfree === true || webProOffer ? 'd-none' : ''}>{t("provpnBullet5")}</li>
                </div>
              </div>
            </div>
            <div ref={panel.pro} className={`col-12 col-sm-10 col-md-8 panels-col-lg-3 col-lg-3 order-1 order-lg-2 d-flex flex-column align-items-center justify-content-start panelShadowpop panelWidth px-0 mx-0 ${panelShadowNone} ${panelTriplet} ${panelPromo}`}>
              {
                //remove most popular for android
              }
              {product === "Web" && (
                <div className="panels-new-improved-badge">
                  <div className="badge-text">
                    {t("New & improved")}
                  </div>
                </div>
              )}
              <div className="popular d-flex align-items-center justify-content-center">{t("POPULAR")}</div>
              <div className="panelTitle d-flex align-items-center mt-4">
                {
                  (product === "Classic") &&
                  <div className="new-badge">{t("NEW")}</div>
                }
                PRO
              </div>
              { titleProduct &&
                <p className="panelTitleProduct"> {t(`for ${product}`)}</p>
              }
              <div className="panelSubtitle px-3 mt-3 mb-4"><div>{t("proPanelTitle")}</div></div>
              <div className="panelSubline mb-4">&nbsp;</div>
              <div>
                <div className="panelsPrice px-3">
                  {/* {((!showPromoDiscount) || !isPromoCalloutPage || isKasperskyTR || isKasperskyKO) */}
                  {((!showProPromo) || !isPromoCalloutPage || isKasperskyTR || isKasperskyKO)
                    ? <span className={androidfree === true ? 'd-none' : 'd-block'}>$19.95</span>
                    : (
                      <>
                        <div className="promo-limited-time">{t("Limited Time Only")}</div>
                        <div className="promo-percent-off">{promoPercent} {t("Off")}</div>
                        <div className="promo-list-price">
                          {t("List Price")}:
                          <span className="promo-original-price">$19.95</span>
                        </div>
                      </>
                    )
                  }
                  {
                    ((androidfree === true) && !isPromoCalloutPage) &&
                    <span className='d-block'>$2.99</span>
                  }
                </div>

                <div className={androidfree === true ? 'invisible mb-3' : 'panelsPeriod mb-3'}>{t("per year")}</div>
              </div>
              <Button panel={panel.pro} onClick={(e) => handleClick(e, proLinkFinal, "pro")} id="panels-pro-buynow" href={proLinkFinal} type="button" className={homepage === true || androidfree === true ? 'd-none' : 'btn btn-outline-primary button-outlined'}>{t("BUY NOW")}</Button>
              {
                (product === "Classic") &&
                <a className="panels-pro-free-trial-link mt-3" href="https://store.bittorrent.com/849/purl-utprotrialweb14" target="_blank" rel="noreferrer">{t("Or try free for 14 days")}</a>
              }
              <div className={homepage === true ? 'd-flex align-items-center justify-content-start' : 'd-none'}>
                <Button id="panels-hp-web-pro-link" onClick={(e) => handleClick(e, webProLinkFinal, "pro", "Web")} href={webProLinkFinal} type="button" className="btn btn-primary-panels button-filled button-panels">WEB</Button>
                <Button id="panels-hp-classic-pro-link" onClick={(e) => handleClick(e, proLinkFinal, "pro", "Classic")} href={proLinkFinal} type="button" className="btn btn-outline-primary button-outlined ml-2 button-panels">CLASSIC</Button>
              </div>
              <a href={androidLinkPro} id="panels-android-pro" className={androidfree === true ? 'index-mobile-google-play' : 'd-none'} target="_blank" rel="noopener noreferrer">
                <img className="google-play-button img-fluid" src={googlePlayButton} alt="µTorrent Pro for Android Google Play Store badge"></img>
              </a>
              <div className="mx-3 mt-4 mw-100">
                <div className={classicPremium === true || classicfree === true || homepage === true || btwebfree === true || webPremium === true ? 'panelBullets w-100 h-100 uniform' : 'panelBullets w-100 h-100 uniform-short'}>
                  {
                    isKasperskyTR &&
                    <li style={{listStyleImage:`url('https://cdn-assets.bittorrent.com/optimize/post-download-page/check-mark-green.svg')`}}><b className="text-dark">1 PC için Kaspersky AV</b></li> 
                  }
                  {
                    isKasperskyKO &&
                    <li style={{listStyleImage:`url('https://cdn-assets.bittorrent.com/optimize/post-download-page/check-mark-green.svg')`}}><b className="text-dark">PC 1대용 Kaspersky Security</b></li> 
                  }
                  <li>{t("proBullet1")}</li>
                  <li>{t("proBullet2")}</li>
                  <li>{t("proBullet3")}</li>
                  <li className={androidfree === true || btwebfree || webPremium || webcompare ? 'd-none' : ''}>{t("proBullet4")}</li>
                  <li className={homepage=== true || androidfree === true || classiccompare === true || webcompare === true || btwebfree === true || webPremium === true || webProOffer ? 'd-none' : ''}>{t("proBullet5")}</li>
                 {/* <li className={(classicPremium === true) && (btwebfree !== true || webPremium === true) ? '' : 'd-none'}>{t("proBullet6")}</li> */}
                </div>
              </div>
            </div>
            <div ref={panel.adFree} className={androidfree === true || webProOffer || !isAPartition ? 'd-none' : `col-12 col-sm-10 col-md-8 panels-col-lg-3 col-lg-3 order-2 order-lg-3 d-flex flex-column align-items-center justify-content-start panelShadowaf panelWidth panelOther ${panelShadowNone} ${panelNoBorder} ${panelPromo}`}>
              <div className="panelTitle mt-4">AD-FREE</div>
              { titleProduct &&
                <p className="panelTitleProduct"> {t(`for ${product}`)}</p>
              }
              <div className="panelSubtitle mt-3 mb-4"><div>{t("afPanelTitle")}</div></div>
              <div className="panelSubline mb-4">&nbsp;</div>
              <div>
                <div className="panelsPrice">
                  {/* {(!showPromoDiscount || !isPromoCalloutPage || isKasperskyTR || isKasperskyKO) */}
                  {!showAdFreePromo
                    ? "$4.95"
                    : (
                      <>
                        <div className="promo-limited-time">{t("Limited Time Only")}</div>
                        <div className="promo-percent-off">{promoPercent} {t("Off")}</div>
                        <div className="promo-list-price">
                          {t("List Price")}:
                          <span className="promo-original-price">$4.95</span>
                        </div>
                      </>
                    )
                  }
                </div>
                <div className="panelsPeriod mb-3">{t("per year")}</div>
              </div>
              <Button panel={panel.adFree} onClick={(e) => handleClick(e, adFreeLinkFinal, "adfree")} id="panels-af-buynow" href={adFreeLinkFinal} type="button" className={homepage === true ? 'd-none' : 'btn btn-outline-primary button-outlined'}>{t("BUY NOW")}</Button>
              <div className={homepage === true ? 'd-flex align-items-center justify-content-start' : 'd-none'}>
                <Button id="panels-hp-web-af-link" onClick={(e) => handleClick(e, webAdFreeLinkFinal, "adfree", "Web")} href={webAdFreeLinkFinal} type="button" className="btn btn-primary-panels button-filled button-panels">WEB</Button>
                <Button id="panels-hp-classic-af-link" onClick={(e) => handleClick(e, adFreeLinkFinal, "adfree", "Classic")} href={adFreeLinkFinal} type="button" className="btn btn-outline-primary button-outlined ml-2 button-panels">CLASSIC</Button>
              </div>
              <div className="mt-4 mw-100">
                <div className={classicPremium === true || classicfree === true || homepage === true || btwebfree || webPremium  ? 'panelBullets w-100 uniform' : 'panelBullets w-100 uniform-short'}>
                  <li>{t("afBullet1")}</li>
                  <li className={classicfree === true || classicPremium === true || classiccompare === true ? 'd-none' : ''}>{t("afBullet2")}</li>
                  <li className={classicfree === true || classicPremium === true || classiccompare === true ? 'd-none' : ''}>{t("afBullet3")}</li>
                </div>
              </div>
            </div>
            <div ref={panel.basic} id="basicpanel" className={homepage === true || webProOffer ? 'd-none' : `col-12 col-sm-10 col-md-8 panels-col-lg-3 col-lg-3 order-4 d-flex flex-column align-items-center justify-content-start panelShadow panelWidth panelOther ${panelShadowNone} ${panelPromo}`}>
              <div className="panelTitle mt-4">BASIC</div>
              { titleProduct &&
                <p className="panelTitleProduct"> {t(`for ${product}`)}</p>
              }
              <div className="panelSubtitle mt-3 mb-4"><div>{t("basicPanelTitle")}</div></div>
              <div className="panelSubline mb-4">&nbsp;</div>
              <div>
                <div className="panelsPrice"><span style={freePriceStyle} className="text-nowrap d-inline-block" ref={freePriceInnerRef}>{t("FREE")}</span></div>
                <div className="panelsPeriod mb-3">&nbsp;</div>
              </div>
              <Button panel={panel.basic} onClick={(e) => handleClick(e, linkBasic, "basic")} id="panels-basic-dlnow" href={linkBasic} type="button" className={androidfree === true ? 'd-none' : 'btn btn-outline-primary button-outlined'}>{t("DOWNLOAD NOW")}</Button>
              <a href={androidLinkFree} id="panels-android-free" className={androidfree === true ? 'index-mobile-google-play' : 'd-none'} target="_blank" rel="noopener noreferrer">
                <img className="google-play-button img-fluid" src={googlePlayButton} alt="µTorrent for Android Google Play Store badge"></img>
              </a>
              <div className="mt-4 mw-100">
                <div className={classicPremium === true || classicfree === true || homepage === true || btwebfree === true || webPremium === true ? 'panelBullets w-100 uniform' : 'panelBullets w-100 uniform-short'}>
                  <li>{t("basicBullet1")}</li>
                  <li className={androidfree === true ? '' : 'd-none'}>{t("basicBullet2")}</li>
                  <li className={androidfree === true ? '' : 'd-none'}>{t("basicBullet3")}</li>
                  {
                    (showEULA) &&
                    <div className="panels-eula-container">
                      <div className="text panels-eula-text mt-2">
                        {t('By clicking Download Now, you agree to our Terms of Use, EULA & Privacy Policy', {
                          "Terms of Use" : <a href="https://www.bittorrent.com/legal/terms-of-use/" target="_blank" rel="noopenner noreferrer">{t(`Terms of Use`)}</a>,
                          "EULA" : <a href="https://www.bittorrent.com/legal/eula/" target="_blank" rel="noopenner noreferrer">{t(`EULA`)}</a>,
                          "Privacy Policy" : <a href="https://www.bittorrent.com/legal/privacy-policy/" target="_blank" rel="noopenner noreferrer">{t(`Privacy Policy`)}</a>,
                        })}
                      </div> 
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
          {children}
        </div>
        {/* <PromoPopup show={showPromoPopup} ref={popupRef} closeEvent = {handlePromoPopupClose}/>         */}

     </>
  )
}

export default ComparisonPanels
